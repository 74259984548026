export type EventType = "projection" | "concert" | "conference"; // | 'workshop';
export type MediaQualificationCode = "TP" | "+0" | "+7" | "+13" | "+16" | "+18";

export interface PostDTO {
  id: number;
  slug: string;
  title: string;
  excerpt: string;
  content: string;
  image_post: string;
  publish_date: number;
}

export interface LocationDTO {
  id: number;
  name: string;
  link?: string;
}

export interface SectionDTO {
  id: string;
  title: string;
}

export interface MediaDTO {
  id: number;
  type: string;
  title: string;
  original_title?: string;
  year: number;
  duration_in_minutes: number;
  excerpt: string;
  sinopsis: string;
  qualification_code: MediaQualificationCode;
  director_names: string[];
  actor_names: string[];
  genre_names: string[];
  tag_names: string[];
  country_names: string[];
  image_card?: string;
  image_poster?: string;
  video_trailer?: string;
  video_background?: string;
}

export enum EventAccessType {
  Free = "free",
  Invitation = "invitation",
  Limited = "limited",
  Full = "full",
  Purchase = "purchase",
  Early_full = "early_full",
}
export interface EventDTO {
  id: number;
  type: EventType;
  slug: string;
  title: string;
  subtitle?: string;
  description?: string;
  start_date: number;
  end_date: number;
  location_id: number;
  section_id: string;
  access_type: EventAccessType;
  access_link?: string;
  image_card: string;
  media?: MediaDTO;
  projection_id?: number;
  video_background?: string;
}

export interface ProjectionDTO extends EventDTO {
  media: MediaDTO;
}

export type EventListDTO = EventDTO[];
export type LocationListDTO = LocationDTO[];
export type SectionListDTO = SectionDTO[];
export type PostsListDTO = PostDTO[];

export interface PartakerDTO {
  id: number;
  full_name: string;
  image_card: string;
  profession: string;
}

export type PartakerListDTO = PartakerDTO[];
