import {
  EventListDTO,
  EventType,
  LocationListDTO,
  SectionListDTO,
} from "@/domain/definitions";
import { EventModel } from "@/domain/models/EventModel";
import { MediaModel } from "@/domain/models/MediaModel";
import { LocationModel } from "@/domain/models/LocationModel";
import { SectionModel } from "@/domain/models/SectionModel";
import { computed, ref, Ref } from "vue";
import { useAPIRequest } from "./useAPIRequest";
import { dateBelongsToFormalDay } from "@/utils/dateTimeFormatter";

/** global events array */
const events = ref<EventModel[]>([]);
const locations = ref<LocationModel[]>([]);
const sections = ref<SectionModel[]>([]);

export const useEvents = (): UseEventsResponse => {
  const {
    execute: _fetchEvents,
    isFetching: isFetchingEvents,
    error: eventsError,
    data: eventDTOs,
  } = useAPIRequest<EventListDTO>("/events");
  const {
    execute: fetchLocations,
    isFetching: isFetchingLocations,
    error: locationsError,
    data: locationDTOs,
  } = useAPIRequest<LocationListDTO>("/locations");
  const {
    execute: fetchSections,
    isFetching: isFetchingSections,
    error: sectionsError,
    data: sectionDTOs,
  } = useAPIRequest<SectionListDTO>("/sections");

  async function fetchEvents() {
    await _fetchEvents();
    if (!eventDTOs.value) {
      return;
    }
    await fetchLocations();
    if (!locationDTOs.value) {
      return;
    }
    await fetchSections();
    if (!sectionDTOs.value) {
      return;
    }

    // initialize Event, Location and Section models,
    // store data in global state

    locations.value = locationDTOs.value.map(
      (locationDTO) => new LocationModel(locationDTO)
    );

    sections.value = sectionDTOs.value.map(
      (sectionDTO) => new SectionModel(sectionDTO)
    );

    events.value = eventDTOs.value
      .filter((v) => v !== null)
      .map((eventDTO) => {
        const event = new EventModel(eventDTO);

        const location = locations.value?.find(
          (location) => event.locationId === location.id
        );
        if (location) {
          event.setLocation(location);
        }
        const section = sections.value?.find(
          (section) => event.sectionId === section.id
        );
        if (section) {
          event.setSection(section);
        }
        if (eventDTO.media) {
          event.setMedia(new MediaModel(eventDTO.media));
        }

        if (eventDTO.projection_id) {
          const slug = eventDTOs.value?.find((e) => {
            return e.id === eventDTO.projection_id;
          })?.slug as string;
          if (slug) {
            event.setProjectionSlug(slug);
          }
        }
        return event;
      });
  }

  function filterEvents(filters?: EventsFilters) {
    const eventsFiltered = events.value.filter((event) => {
      return (
        filters === undefined ||
        ((!filters.day ||
          dateBelongsToFormalDay(
            event.startDate.getTime(),
            new Date(filters.day).getTime()
          )) &&
          (!filters.eventType || event.type === filters.eventType) &&
          (!filters.locationId || event.locationId == filters.locationId) &&
          (!filters.sectionId || event.sectionId == filters.sectionId))
      );
    });

    return eventsFiltered;
  }

  function getEvent(eventId: number) {
    const event = computed(() =>
      events.value.find((event: EventModel) => event.id === eventId)
    );
    if (!locationDTOs.value || !eventDTOs.value) {
      throw new Error("Before calling getEvent(), call fetchEvents() first");
    }

    return event;
  }

  async function getEventBySlug(slug: string): Promise<EventModel | undefined> {
    if (!events.value.length) {
      await fetchEvents();
    }
    const event = events.value.find((event) => event.slug === slug);
    return event;
  }

  return {
    fetchEvents,
    isFetchingEvents: computed(
      () =>
        isFetchingEvents.value ||
        isFetchingLocations.value ||
        isFetchingSections.value
    ),
    getEventBySlug,
    eventsError: computed(
      () => eventsError.value || locationsError.value || sectionsError.value
    ),
    events,
    filterEvents,
    getEvent,
    locations,
    sections,
  };
};

interface UseEventsResponse {
  isFetchingEvents: Ref<boolean>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventsError?: Ref<any>;
  events: Ref<EventModel[]>;
  getEventBySlug: (slug: string) => Promise<EventModel | undefined>;
  locations: Ref<LocationModel[]>;
  sections: Ref<SectionModel[]>;

  fetchEvents: () => Promise<void>;

  /** Gets all events matching filter */
  filterEvents: (filters?: EventsFilters) => EventModel[];

  getEvent: (eventId: number) => Ref<EventModel | undefined>;
}

export interface EventsFilters {
  day?: string; // format YYYY-mm-dd ?
  locationId?: number;
  sectionId?: string;
  eventType?: EventType;
}
