import { LocationDTO } from "../definitions";

export class LocationModel {
  readonly id: number;
  readonly name: string;
  readonly link?: string;

  constructor(locationDTO: LocationDTO) {
    this.id = locationDTO.id;
    this.name = locationDTO.name;
    this.link = locationDTO.link;
  }
}
