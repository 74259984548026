
import { defineComponent, PropType } from "vue";

type LabelColor =
  | "amff-black"
  | "amff-white"
  | "amff-pink"
  | "amff-pink-invert"
  | "amff-light-pink";

export default defineComponent({
  props: {
    color: { type: String as PropType<LabelColor> },
    outline: { type: Boolean, default: false },
  },
});
