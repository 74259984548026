import {
  EventDTO,
  ProjectionDTO,
  EventType,
  EventAccessType,
} from "../definitions";
import { LocationModel } from "./LocationModel";
import { SectionModel } from "./SectionModel";
import { MediaModel } from "./MediaModel";
import { formatMinutesForHumans, formatDay } from "@/utils/dateTimeFormatter";
export class EventModel {
  readonly id: number;
  readonly type: EventType;
  readonly slug: string;
  readonly title: string;
  readonly subtitle?: string;
  readonly description?: string;
  readonly startDate: Date;
  readonly endDate: Date;
  readonly day: string; // YYYY-MM-DD format
  readonly hour: string; // HH:mm format
  readonly locationId: number;
  readonly sectionId: string;
  readonly accessType: EventAccessType;
  readonly accessLink?: string;
  readonly imageCard: string;
  readonly videoBackground?: string;
  public projectionSlug?: string;
  public media?: MediaModel;
  public location?: LocationModel;
  public section?: SectionModel;

  constructor(eventDTO: EventDTO | ProjectionDTO) {
    this.id = eventDTO.id;
    this.type = eventDTO.type;
    this.slug = eventDTO.slug;
    this.title = eventDTO.title;
    this.subtitle = eventDTO.subtitle;
    this.description = eventDTO.description;
    this.startDate = new Date(eventDTO.start_date * 1000);
    this.endDate = new Date(eventDTO.end_date * 1000);
    this.locationId = eventDTO.location_id;
    this.accessType = eventDTO.access_type;
    this.accessLink = eventDTO.access_link;
    this.imageCard = eventDTO.image_card;
    this.videoBackground = eventDTO.video_background;
    // https://stackoverflow.com/a/58633651/4217158
    const isoDate = this.startDate.toLocaleString("sv", {
      timeZone: "Europe/Madrid",
    });
    this.day = isoDate.slice(0, 10);
    this.hour = isoDate.slice(11, 16);
    this.sectionId = eventDTO.section_id;
  }

  public setLocation(location: LocationModel): void {
    this.location = location;
  }

  public setSection(section: SectionModel): void {
    this.section = section;
  }

  public setMedia(media: MediaModel): void {
    this.media = media;
  }
  public setProjectionSlug(slug: string): void {
    this.projectionSlug = slug;
  }

  public getDurationFormatted(lang?: string): string {
    return formatMinutesForHumans(
      Math.round((this.endDate.getTime() - this.startDate.getTime()) / 60000),
      lang
    );
  }

  public getDayFormatted(
    format: "short" | "long" = "short",
    lang?: string
  ): string {
    if (format === "short") {
      return this.day.slice(8, 10) + "." + this.day.slice(5, 7);
    }

    return formatDay(this.day, lang as string);
  }

  public getAccessTypeFormatted(lang?: string): string {
    if (this.accessType === EventAccessType.Free) {
      return lang === "es" ? "Gratuito" : "Gratuit";
    } else if (this.accessType === EventAccessType.Invitation) {
      return lang === "es" ? "Con invitación" : "Amb invitació";
    } else if (this.accessType === EventAccessType.Limited) {
      return lang === "es" ? "Aforo limitado" : "Aforament limitat";
    } else if (this.accessType === EventAccessType.Early_full) {
      return lang === "es"
        ? "Entradas anticipadas agotadas"
        : "Entradas anticipades esgotades";
    } else {
      // if (this.accessType === "full") {
      return lang === "es" ? "Aforo completo" : "Aforament complet";
    }
  }
}
