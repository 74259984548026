import { MediaDTO, MediaQualificationCode } from "../definitions";
import { formatMinutesForHumans } from "@/utils/dateTimeFormatter";

export class MediaModel {
  readonly id: number;
  readonly type: string;
  readonly title: string;
  readonly originalTitle?: string;
  readonly year: number;
  readonly durationInMinutes: number;
  readonly excerpt: string;
  readonly sinopsis: string;
  readonly qualificationCode: MediaQualificationCode;
  readonly directorNames: string[];
  readonly actorNames: string[];
  readonly genreNames: string[];
  readonly tagNames: string[];
  readonly countryNames: string[];
  readonly imageCard?: string;
  readonly imagePoster?: string;
  readonly videoTrailer?: string;
  readonly videoBackground?: string;

  constructor(MediaDTO: MediaDTO) {
    this.id = MediaDTO.id;
    this.type = MediaDTO.type;
    this.title = MediaDTO.title;
    this.originalTitle = MediaDTO.original_title;
    this.year = MediaDTO.year;
    this.durationInMinutes = MediaDTO.duration_in_minutes;
    this.excerpt = MediaDTO.excerpt;
    this.sinopsis = MediaDTO.sinopsis;
    this.qualificationCode = MediaDTO.qualification_code;
    this.directorNames = MediaDTO.director_names;
    this.actorNames = MediaDTO.actor_names;
    this.genreNames = MediaDTO.genre_names;
    this.tagNames = MediaDTO.tag_names;
    this.countryNames = MediaDTO.country_names;
    this.imageCard = MediaDTO.image_card;
    this.imagePoster = MediaDTO.image_poster;
    this.videoTrailer = MediaDTO.video_trailer;
    this.videoBackground = MediaDTO.video_background;
  }

  public getDurationFormatted(lang: string): string {
    return formatMinutesForHumans(this.durationInMinutes, lang);
  }
}
