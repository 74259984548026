import { SectionDTO } from "../definitions";

export class SectionModel {
  readonly id: string;
  readonly title: string;

  constructor(sectionDTO: SectionDTO) {
    this.id = sectionDTO.id;
    this.title = sectionDTO.title;
  }
}
