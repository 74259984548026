<template>
  <svg
    role="img"
    width="160"
    viewBox="0 0 80 20"
    class="text-amff-yellow-2024 loading-container"
  >
    <title>Cargando / Carregant</title>
    <circle cx="10" cy="10" r="10" fill="currentColor" class="loading-dot" />
    <circle cx="30" cy="10" r="10" fill="currentColor" class="loading-dot" />
    <circle cx="50" cy="10" r="10" fill="currentColor" class="loading-dot" />
    <circle cx="70" cy="10" r="10" fill="currentColor" class="loading-dot" />
  </svg>
</template>

<style lang="scss">
$scale: 0.6;
$speed: 1s;
$delay: 0.2s;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dotLoop {
  0% {
    transform: scale($scale);
  }
  60% {
    transform: scale($scale);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale($scale);
  }
}

.loading-container {
  opacity: 0;
  animation: fadeIn 0.2s ease-out;
  animation-delay: $delay;
  animation-fill-mode: forwards;
}

.loading-dot {
  transform-box: fill-box;
  transform: scale($scale);
  transform-origin: 50% 50%;
  animation: dotLoop $speed ease-out infinite;
}

.loading-dot:nth-child(2) {
  animation-delay: $speed / 4;
}
.loading-dot:nth-child(3) {
  animation-delay: ($speed / 4) * 2;
}
.loading-dot:nth-child(4) {
  animation-delay: ($speed / 4) * 3;
}
</style>
