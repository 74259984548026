
import { computed, defineComponent } from "vue";
import { cdn } from "@/utils/cdn";

export default defineComponent({
  props: {
    path: { type: String, required: true },
  },
  setup(props) {
    const src = computed(() => cdn(props.path, "750x437"));

    const srcset = computed(
      () =>
        `${cdn(props.path, "375x0")} 375w, ` +
        `${cdn(props.path, "750x437")} 750w, ` +
        `${cdn(props.path, "1500x874")} 1500w`
    );

    return { src, srcset };
  },
});
